import '../Tax-Settings/TaxSettings.css';
import React, {Component, useEffect, useState, useRef} from 'react';
import Paper from '@mui/material/Paper';
import { Row, Col, Table, Modal } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { HiSave } from "react-icons/hi";
import { AiOutlineClose, AiFillMinusCircle } from "react-icons/ai";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiFillPrinter } from "react-icons/ai";
import { MdFindReplace } from "react-icons/md";
import { GoSettings } from "react-icons/go";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useHistory, useParams } from "react-router-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import * as RiIcons from "react-icons/ri";

import '../Retail-Sale/RetailSale.css'



const columns = [
    { id: '1', label: 'SI No.', minWidth: 50,span: false },
    { id: '2', label: 'Tax Name', minWidth: 50,span: false },
    { id: '3', label: 'Tax %', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];
    
  const rows = [
    {
      company: 'Lorem Ipsum',

    },
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
  ];

function Retailsale(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [productmodal, setProductModal] = useState(false)

    const [authmodal, setAuthmodal] = useState(false)

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const [paymenttype, setPaymenttype] = useState("")

    const [paymentrcvd, setPaymentrcvd] = useState(0)

    const [qty, setQty] = useState(1)

    const [billamount, setBillamount] = useState(0)

    const [counter, setCounter] = useState("")

    const [totalbalance, setTotalbalance] = useState("")
    const [combotable, setCombotable] = React.useState([{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: ""}]);
    const [baseqty, setbaseQty] = React.useState('1');
    const [age, setAge] = React.useState('');
    const [date, setDate] = React.useState(new Date);
    const [textArray, setTextarray] = React.useState([{key: "", value: ""}])
    const [selectedIndex, setSelectedindex] = React.useState(0)
    const [code, setCode] = useState("");
    const [productlist, setProductlist] = React.useState([]);
    const [productlistitem, setProductlistitem] = React.useState([]);
    const [productnameitem, setProductnameitem] = React.useState([]);
    const [productname, setProductname] = useState("");
    const [customer, setCustomer] = useState(1)
    const [address, setAddress] = useState("")
    const [modal, setModal] = useState(false)
    const [modal2, setModal2] = useState(false)
    const [cardmodal, setCardmodal] = useState(false)
    const [invoiceno, setInvoiceno] = React.useState('');
    const [errormsg, seterrormsg] = React.useState('');
    const [discountamound, setDiscountamound] = React.useState(0);
    const [discountpercentage, setDiscountpercentage] = React.useState(0);
    const [recall, setRecall] = React.useState(false);
    const [invoicelists, setInvoicelist] = React.useState([]);
    const [userId, setUserid] = React.useState("");
    const [clickedamt, setClickedamt] = React.useState(0);
    const [aftersum, setAftersum] = React.useState(0);

    const [lookupproductlist, setLookupproductlist] = React.useState([]);

    const [search, setSearch] = React.useState("");

    const [discard, setDiscard] = React.useState("");

    const [startShit, setStartshift] = React.useState("");
    const [endShift, setEndshift] = React.useState("");
    const handle = useFullScreenHandle();

    const [grandtotalex, setGrandtotalex] = React.useState(0);
    const [customerpricelevel, setCustomerpricelevel] = React.useState("");

    let history = useHistory();

    const [blocked, setBlocked] = React.useState(0)

    const [card, setCard] = React.useState(0);
    const [refno, setRefno] = React.useState("");
    const [cash, setCash] = React.useState(0);
    const [cashtotal, setCashtotal] = React.useState(0);

    const [bank, setBank] = useState("")

    const [cashamount, setCashamount] = useState("")
    const [bankamount, setBankamount] = useState("")

    const [paymode, setPaymode] = useState("")
    const [round, setRound] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [gross, setGross] = useState(0)
    const [grandTotal, setGrandtotal] = useState(0)
    const [balance, setBalance] = useState(0)
    const [otherCharges, setOthercharge] = useState(0)
    const [numarray, setNumarray] = useState([])

    const [billtypelist, setBilltypelist] = React.useState([]);

    const [billType, setBilltype] = React.useState('');
    const [tableRow,setTableRow] = React.useState([{key:"",product_batch_id:"",product_id: "", code: "", productname: "", pricelevel: "", sod_qty: "", sd_baseqty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, sod_id: "",color: "", size: "",colorsize: [],addnew: true, fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: ""}] }])
    const [addnew, setAddnew] = React.useState(false);
    const [payable, setPayable] = React.useState(0);
    const [discardlist, setDiscardlist] = React.useState([])
    const [comboproductlist, setComboproductlist] = React.useState([]);
    const [popupindex, setPopupindex] = React.useState("");
    const [combopopupid, setCombopopupid] = React.useState("");
    const [combobatchlist, setCombobatchlist] = React.useState([{key: "", value: []}]);

    const [cashbutton, setCashbutton] = React.useState(true);
    const [bankbutton, setBankbutton] = React.useState(false);
    const [cashbankbutton, setCashbankbutton] = React.useState(false);

     const [mobile, setMobile] = React.useState("");
     const [editprice, setEditprice] = React.useState("");

    const [total, setTotal] = React.useState(0);
    const hasResults = code.length > 0;
    const hasResultsProduct = productname.length > 0;

    const inputRef = useRef();
    const resultsRef = useRef();
    const inputRef1 = useRef();


    useEffect(() => {
        getInvoiceno(billType)
    },[billType])


    const cashbuttonClick = () => {
        setCash(0)
        setCard(0)
        setClickedamt('')
        setTotalbalance(0)
        setCashbutton(true)
        setBankbutton(false)
        setCashbankbutton(false)
    }
    const bankcashbuttonClick = () => {
        setClickedamt('')
        setTotalbalance(0)
        setCash(0)
        setCard(0)
        setCashbutton(false)
        setBankbutton(false)
        setCashbankbutton(true)
    }
    const bankbuttonClick = () => {
        setCash(0)
        setCard(0)
        setCashbutton(false)
        setBankbutton(true)
        setCashbankbutton(false)
    }

    const deletePopupitem = (index) => {
        console.log("delete index", index)
         var input = [...combotable]
         input.splice(index, 1)
         setCombotable(input)
     }

     const addRow = () => {
        setAddnew(true)
        setTableRow([...tableRow, {key:"",product_batch_id:"", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, pb_stock: "", sales_iscombo: "", sod_id: "",color: "", size: "",colorsize: [],addnew: true, fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "",  stock: ""}]}])
    }

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const discardChange = (event) => {
        setDiscard(event.target.value)
        getDetails(event.target.value)
        
    }

    useEffect(() => {
        payClick()
    }, [billamount, discountamound])

    useEffect(() => {
        totalbalanceclick()
    }, [clickedamt, cash])

    const totalbalanceclick = () => {
        var totalbal = parseFloat(clickedamt) - parseFloat(cash)
        setTotalbalance(totalbal)
    }

    useEffect(() => {
        cashClick()
    }, [cash, card])

    useEffect(() => {
        balanceClick()
    }, [cash, card, billamount, discountamound])

    const payClick = () => {
        var payment = parseFloat(billamount) - parseFloat(discountamound)
        setPayable(payment)
    }

    const cashClick = () => {
        var total = parseFloat(cash) + parseFloat(card)
        setCashtotal(total)
    }

    const balanceClick = () => {
        var amt = parseFloat(billamount) - parseFloat(discountamound) - ( parseFloat(card) + parseFloat(cash)) 
       
        setBalance(amt)
    }

    useEffect(() => {
        percentageClick()
    },[discountpercentage, billamount])

    const percentageClick = () => {
        var tota = parseFloat(billamount) *(parseFloat(discountpercentage) / 100)
        setDiscountamound(tota.toFixed(2))
    }


    const getBilltype = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
  
        var data = {
          "type":  userdata.user_type,
          "companyid": company,
          "id" : 9
        }
        fetch(global.url + "billsett_tranNames", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("list response", responseJson)
              setBilltypelist(responseJson.data)
              setBilltype(responseJson.data[0].billset_id)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

    const getDetails = (id) => {
        var data = {
            "sales_draft_id" : id,
        }
        
        fetch(global.url + "posDiscardDetail", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("posDiscardDetail", responseJson)
            setTableRow(responseJson.data)
          
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getDiscardlist = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        var data = {
            "userid" : userdata.user_id,
        }
        
        fetch(global.url + "posListDiscard", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("discardlist", responseJson)
            if(responseJson.error == false){
                setDiscardlist(responseJson.data)
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    useEffect(() => {
        inputRef.current.focus();
        getBilltype()
        getDiscardlist()
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        var username = JSON.parse(localStorage.getItem("user_name"))
        setUsername(username)
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        setStartshift(userdata.us_start_time)
        setEndshift(userdata.us_end_time)
        setCounter(userdata.user_cashcounter_fk)
    }, []);



    useEffect(() => {
        getTotal()
        
    }, [cashamount, bankamount,]);

    useEffect(() => {
        getTotalamount()
        
    }, [tableRow]);

    const getTotalamount = () => {
        var totalAmount = 0;
        for (var i = 0; i < tableRow.length; i++) {
          totalAmount += tableRow[i].sd_total_amount
       }
        setTotal(totalAmount)
        setGross(totalAmount)
        var round = Math.round(totalAmount)
        setRound(round)
        setGrandtotal(round)
    }

    const getRoundoff = () => {
        var discount1 = (total/100)* discount
        var round = Math.round(total) - discount1
        setRound(Math.round(round))
        setGrandtotal(Math.round(round))
        setGross(total)
    }

    const getTotal = () => {
        var total = cashamount + bankamount
        setCashtotal(total)
    }

    const getInvoiceno = () => {
        var data = {
            "id" : billType
        }
        console.log('invoice data', data)
        fetch(global.url + "billset_invoice", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("salesinvoiceno", responseJson.data)
              if(responseJson.error == false){
                setInvoiceno(responseJson.data)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
      }

    useEffect(() => {
        if ( hasResults ) {
          document.body.addEventListener('keydown', onKeyDown);
        } else {
          document.body.removeEventListener('keydown', onKeyDown);
        }

        if ( hasResultsProduct ) {
            document.body.addEventListener('keydown', onKeyDown1);
        } else {
            document.body.removeEventListener('keydown', onKeyDown1);
        }

        
        return () => {
          document.body.removeEventListener('keydown', onKeyDown);
          document.body.removeEventListener('keydown', onKeyDown1);
        }
      }, [hasResults, hasResultsProduct]);


      function onKeyDown(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef.current;
    
        const resultsItems = Array.from(resultsRef.current.children)
    
        const activeResultIndex = resultsItems.findIndex(child => {
          return child.querySelector('a') === document.activeElement;
        });
    
        if ( isUp ) {
          console.log('Going up!');
          if ( inputIsFocused ) {
            resultsItems[resultsItems.length - 1].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex - 1] ) {
            resultsItems[activeResultIndex - 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
    
        if ( isDown ) {
          console.log('Going down!')
          if ( inputIsFocused ) {
            resultsItems[0].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex + 1] ) {
            resultsItems[activeResultIndex + 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
      }

      function onKeyDown1(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef1.current;
    
        const resultsItems = Array.from(resultsRef.current.children)
    
        const activeResultIndex = resultsItems.findIndex(child => {
          return child.querySelector('a') === document.activeElement;
        });
    
        if ( isUp ) {
          console.log('Going up!');
          if ( inputIsFocused ) {
            resultsItems[resultsItems.length - 1].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex - 1] ) {
            resultsItems[activeResultIndex - 1].querySelector('a').focus();
          } else {
            inputRef1.current.focus();
          }
        }
    
        if ( isDown ) {
          console.log('Going down!')
          if ( inputIsFocused ) {
            resultsItems[0].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex + 1] ) {
            resultsItems[activeResultIndex + 1].querySelector('a').focus();
          } else {
            inputRef1.current.focus();
          }
        }
      }


    const handleOnChange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].code = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setCode(event.currentTarget.value);
        getSearch(event.currentTarget.value, type)
    }

    

    const getSearch = (text, type) => {
        if(type == "code") {
            var data = {
                "type" : type,
                "product_code":  text
            }
        }
        else if(type == "intercode") {
          var data = {
              "type" : type,
              "intercode":  text
          }
      }
        else {
            var data = {
                "type" : type,
                "refcode":  text
            }
        }
        console.log("searchdata", data)
        
        fetch(global.url + "posGetSearch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response combo", responseJson)
            setProductlist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const totalDiscountchange = (event) => {
        setDiscount(event)
        var disc = (total/100) * event
        var result = total - disc
        setGross(result)
        setRound(Math.round(result))
        setGrandtotal(Math.round(result))
    }


    const listClickcode = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount, percentage, stock, iscombo, color, size, colorsize1) => {
        console.log("color1", colorsize1)
        console.log("customer percentege", customerpricelevel)
        var input = [...tableRow]
        input[index].key = index
        input[index].product_batch_id = batchid
        input[index].code = code
        input[index].pb_stock = stock
        input[index].productname = name
        // input[index].product_fetchiscombo = iscombo
        input[index].sales_iscombo = iscombo
        input[index].sd_qty = qty
        input[index].unit = unit
        input[index].color = color
        input[index].size = size
        input[index].addnew = true
        input[index].fetch_products = [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: ""}]

        
        
        console.log("no customer")
            
        console.log("no customer no per")
        input[index].price = salesrate
        input[index].calcprice = calcprice
        input[index].discount = discount
        if(discount) {
        
            var totalcalc = (calcprice/100) * discount
        }
        else {
        
            var totalcalc = 0
        }
        input[index].pricelevel = pricelevel
        input[index].sd_total_amount = calcprice - totalcalc

        
        
        
        setTableRow([...tableRow, input])
        setTableRow([...tableRow, {key:"",product_batch_id:"",product_id: "", code: "", productname: "", pricelevel: "", sd_qty: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: 0, pb_stock: "", sales_iscombo: "",sod_id: "",color: "", size: "",colorsize: [], addnew: true,  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
        setCode("")
        
        var total1 = total + totalcalc
        console.log("totalcalc", total1)
        setTotal(total1)
  }

    const listClickrefcode = (index, edit, productid, pbid, productrefcode,productname,products,image, stock, iscombo) => {

        console.log("edit", edit)
        setEditprice(edit)
          var input = [...tableRow]
          input[index].key = index
          input[index].products_id = productid
          input[index].code = productrefcode
          input[index].sales_iscombo = iscombo
          input[index].productname = productname
          input[index].pei_image = image
          input[index].colorsize = products
          input[index].addnew = true
          input[index].sd_qty = stock
        
          input[selectedIndex].product_batch_id = pbid
          getProductbybatch1(pbid, selectedIndex)
        //   colorChange(pbid)
          setSelectedindex(index)
          setTableRow([...tableRow, input])
        //   setTableRow([...tableRow, {key:"",product_batch_id:"",product_id: "", code: "", productname: "", pricelevel: "", sd_qty: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: 0, pb_stock: "", sales_iscombo: "",sod_id: "",color: "", size: "",colorsize: [],addnew: true,  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
          setCode("")
      }

    const handleProductchange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].productname = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setProductname(event.currentTarget.value);
        getSearch(event.currentTarget.value, type)
    }

    const listClickname = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount) => {
            var input = [...tableRow]
            input[index].key = index
            input[index].product_batch_id = batchid
            input[index].code = code
            input[index].productname = name
            input[index].pricelevel = pricelevel
            input[index].rsd_qty = qty
            input[index].unit = unit
            input[index].price = salesrate
            input[index].calcprice = calcprice
            input[index].discount = discount
            // if(discount) {
            //     var totalcalc = (calcprice/100) * discount
            // }
            // else {
            //     var totalcalc = calcprice
            // }
            
            input[index].sd_total_amount = calcprice 
            setTableRow([...tableRow, input])
            setTableRow([...tableRow, {key:"",product_batch_id:"",barcode:"", code: "", productname: "", pricelevel: "", sd_qty: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: 0 }])
            setProductname("")
    }

    const deleteRow = (index, price) => {
        console.log("index", index)
        setTotal(total-price)
        var input = [...tableRow]
        input.splice(index, 1)
        console.log("input", input)
        setTableRow(input)
        // setAuthmodal(true)
      }

      const paymenttypeChange = () => {

      }

      const plusQty = () => {
        setQty(prevActiveStep => prevActiveStep + 1)
      }

      const minusqty = () => {
        if(qty != 1){
            setQty(prevActiveStep => prevActiveStep - 1)
        }
      }

      const onPopup = () => {
            setModal(true)
      }

      const paymodeChange = (event) => {
        setPaymode(event.target.value);
      };

      const otherChargeschange = (event) => {
        setOthercharge(event)
        var disc = (total/100) * discount
        var result = (total - disc) + parseInt(event) 
            if(result) {
                setGrandtotal(result)
            }
            
        }

      const cashChange = (e) => {
        let isChecked = e.target.checked;
        if(isChecked == true) {
            setCash(1)
            setPaymode("cash")
        }
        else {
            setCash(0)
            setPaymode("")
        }
        // do whatever you want with isChecked value
      }

      const bankChange = (e) => {
        let isChecked = e.target.checked;
        if(isChecked == true) {
          setBank(1)
          setPaymode("bank")
        }
        else {
           setBank(0)
           setPaymode("")
        }
        // do whatever you want with isChecked value
      }

      const onSave = () => {
        if(cashtotal == payable){
            const exampleArray = []
            for(var i = 0 ; i< tableRow.length; i++) {
                if(tableRow[i].product_batch_id == "") {
                    // tableRow.splice(i, 1)
                }
                else{
                    exampleArray.push(tableRow[i])
                    exampleArray[i].sd_qty = tableRow[i].sd_qty * baseqty
                    exampleArray[i].sd_baseqty = baseqty
                } 
            }
            console.log("tableRow", exampleArray)
            var data = {
                "sales_user_id" : userId,
                "sales_bill_type":  billType,
                "sales_ref_no":  "",
                "sales_salesman":  "",
                "sales_invoice":  invoiceno,
                "sales_date":  date.toISOString().split('T')[0],
                "sales_lpo_no":  "",
                "sales_lpo_date":  "",
                "sales_customer":  customer,
                "sales_del_no":  "",
                "sales_order_no":  "",
                "sales_address":  "",
                "sales_mob_no":  mobile,
                "sales_qt_no":  "",
                "sales_crdt_limit":  "",
                "sales_balance":  "",
                "sales_limit_balance":  "",
                "sales_total":  billamount,
                "sales_discount":  discountamound,
                "sales_other_charges":  "",
                "sales_advance":  "",
                "sales_details": exampleArray,
                "sales_branchid" : userbranchid, 
                "sales_companyid" : usercompanyid,
                "sales_id": "",
                "sales_zone": "",
                "sales_reference": "",
                "payable": payable,
                "sales_type": 1,
                "sales_card": card,
                "sales_card_reference": refno,
                "sales_counter": counter,
                "sales_cash" : cash,
                "sales_cashtotal" : cashtotal,
                "sales_cashreceived" : clickedamt,
                "sales_balanceremain" : totalbalance
            }
            console.log("data", data)
            fetch(global.url + "updatePos", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("response pos", responseJson)
                if(responseJson.error == true) {
                    alert(responseJson.message)

                }
                else{
                    alert(responseJson.message)
                    const win = window.open('/print/'+ responseJson.data.sales_id, "_blank");
                    win.focus();
                    window.location.reload()
                    
                }
            })
            .catch((error)=>{
                console.log(error)
            })
        }else{
            alert('please enter cash/card amount correctly!')
        }
    }

    //   function calculate(value) {
    //     if(value != undefined) {
    //         let sum = 0;
    //         numArray.push(value)
    //         for (let i = 0; i < numArray.length; i++) {
    //             sum += numArray[i];
    //         }
            
    //         document.getElementById("clickedBtn").value = sum;
    //     }
    //     else{
    //         let sum = 0;
    //         numArray = []
    //         document.getElementById("clickedBtn").value = sum;
    //     }
    // }
    const calculate = (value) => {
        if(value != undefined) {
            var input = [...numarray]
            input.push(value)
            setNumarray(input)  
        }
        else{
            setClickedamt(0)
            setNumarray([])
        }
    }

    const cancelItemclick = (index, price) => {
        console.log("index", index)
        setBillamount(billamount-price)
        var input = [...tableRow]
        input.splice(index, 1)
        setTableRow(input)
    }

    const billtypeChange = (event) => {
        setBilltype(event.target.value);
        getInvoiceno()
      };

    useEffect(() => {
        var sum = 0
        for (let i = 0; i < numarray.length; i++) {
            sum += numarray[i];
        }
        setClickedamt(sum)
    }, [numarray]);

    useEffect(() => {
        var totalAmount = 0;
        for (var i = 0; i < tableRow.length; i++) {
            console.log("loop total", tableRow[i].sd_total_amount)
            totalAmount += parseInt(tableRow[i].sd_total_amount)
            console.log("single total", totalAmount)
            
         } 
         console.log("total", totalAmount)
         setBillamount(totalAmount)
    }, [tableRow]);

    useEffect(() => {
        var input = [...tableRow]
        console.log("input", input)
        input[selectedIndex].sd_qty = qty
        input[selectedIndex].sd_total_amount = qty * input[selectedIndex].price
        setTableRow(input)
    }, [qty]);

    useEffect(() => {
       setQty(1)
    }, [selectedIndex]);

    // useEffect(() => {
    //     var result = clickedamt - billamount
    //     setBalance(result)
    //  }, [clickedamt, billamount]);
    

    // const onApply = () => {
    //     var result = clickedamt - grandTotal
    //     setAftersum(result)
    // }

    const lookupClick = () => {
        setProductModal(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
    
        var data = {
          "usertype": userdata.user_type,
          "companyid": company
        }
        fetch(global.url + "viewAllproductbatch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("item look up", responseJson)
              setLookupproductlist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    const suspendClick = () => {
        const exampleArray = []
            for(var i = 0 ; i< tableRow.length; i++) {
                if(tableRow[i].product_batch_id == "") {
                    // tableRow.splice(i, 1)
                }
                else{
                    exampleArray.push(tableRow[i])
                } 
            }
            console.log("tableRow", exampleArray)
            var data = {
                "sales_user_id" : userId,
                "sales_bill_type":  billType,
                "sales_ref_no":  "",
                "sales_salesman":  "",
                "sales_invoice":  invoiceno,
                "sales_date":  date.toISOString().split('T')[0],
                "sales_lpo_no":  "",
                "sales_lpo_date":  "",
                "sales_customer":  customer,
                "sales_del_no":  "",
                "sales_order_no":  "",
                "sales_address":  "",
                "sales_mob_no":  "",
                "sales_qt_no":  "",
                "sales_crdt_limit":  "",
                "sales_balance":  "",
                "sales_limit_balance":  "",
                "sales_total":  billamount,
                "sales_discount":  discountamound,
                "sales_other_charges":  "",
                "sales_advance":  "",
                "sales_details": exampleArray,
                "sales_branchid" : userbranchid, 
                "sales_companyid" : usercompanyid,
                "sales_id": "",
                "sales_zone": "",
                "sales_reference": "",
                "payable": payable,
                "sales_type": 1,
                "sales_card": card,
                "sales_card_reference": refno,
                "sales_counter": counter,
                "sales_cash" : cash,
                "sales_cashtotal" : cashtotal
            }
            console.log("data", data)
        fetch(global.url + "posDiscard", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response", responseJson)
            if(responseJson.error == true) {
                alert(responseJson.message)
            }
            else{
                alert(responseJson.message)
                window.location.reload();
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    // const onfilter = (text) => {
    //     var branch = JSON.parse(localStorage.getItem("branch"))
    //     var company = JSON.parse(localStorage.getItem("company"))
    //     var data = {
    //         "type" : "refcode",
    //         "refcode":  text,
    //         "companyid" : company,
    //         "branchid" : branch
    //     }
    //     console.log("searchdata", data)
    //     setSearch(text)
    //     fetch(global.url + "getProductbySearch", {
    //         method: "POST",
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(data),
    //     })
    //     .then((response)=> response.json())
    //     .then((responseJson)=>{
    //         console.log("response", responseJson)
    //         setLookupproductlist(responseJson.data)
    //     })
    //     .catch((error)=>{
    //         console.log(error)
    //     })
    // }

    const onfilter = (text, type) => {
        console.log("pro text", text)
        console.log("pro type", type)
        if(type == "intercode") {
            var data = {
                "type" : 'intercode',
                "intercode":  text
            }
            fetch(global.url + "posGetSearch", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("response combo", responseJson)
                setLookupproductlist(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
        }
        if(type == "name") {
            var data = {
                "type" : 'name',
                "product_name":  text
            }
            fetch(global.url + "posGetSearch", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("response combo", responseJson)
                setLookupproductlist(responseJson.data[0].products)
            })
            .catch((error)=>{
                console.log(error)
            })
        }
    }

    const returnClick = () => {
        // var data = {
        //     "barcode" :  tableRow[0].barcode,
        //     "companyid":  usercompanyid,
        //     "branchid":  userbranchid,
        // }
        // console.log("data", data)
        // fetch(global.url + "posSalesReturnEach", {
        //     method: "POST",
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify(data),
        // })
        // .then((response)=> response.json())
        // .then((responseJson)=>{
        //     console.log("response", responseJson)
        //     if(responseJson.error == false){
                
        //         alert(responseJson.message)
        //         window.location.reload()
        //     }
        //     else{
        //         alert(responseJson.message)
        //     }
        // })
        // .catch((error)=>{
        //     console.log(error)
        // })
        alert("Only Admin can access!")
    }


    const checkChange = (e) => {
        let isChecked = e.target.checked;
        if(isChecked == true) {
          setBlocked(1)
        }
        else {
          setBlocked(0)
        }
        // do whatever you want with isChecked value
      }

      const colorChange = (id) => {
        var input=[...tableRow]
        console.log("selectedIndex", selectedIndex)
        input[selectedIndex].product_batch_id = id
        setTableRow(input)
        
        getProductbybatch1(id, selectedIndex)
      //   setColor(event.target.value)
    }

    const qtyChange = (event, index) => {
        var textArray1 = [...tableRow]
        if(event.target.value > parseFloat(textArray1[index].pb_stock)){
            console.log("event.target.value", event.target.value)
            console.log("event.target.value", textArray1[index].pb_stock)
            seterrormsg("Can't enter qty greater than stock")
        }
        else{
            seterrormsg("")
            console.log("price", textArray1[index].price)
            var totalprice = textArray1[index].price * event.target.value
            textArray1[index].sd_qty = event.target.value
            textArray1[index].calcprice = totalprice
            textArray1[index].sd_total_amount = totalprice
            setTableRow(textArray1)
        }
    }

    const priceChange = (event, index) => {
        var textArray1 = [...tableRow]
        textArray1[index].price = event.target.value
        var totalprice =  event.target.value * textArray1[index].sd_qty
        textArray1[index].calcprice = totalprice
        textArray1[index].sd_total_amount = totalprice
        setTableRow(textArray1)
    }

    const getProductbybatch1 = (id, index) => {
   
        var data = {
            "pb_id" : id,
        }
        console.log("data", data)
        fetch(global.url + "getProductBatchById", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
  
            setDetails(id, index, responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
      }
      const setDetails = (id, index, responseJson) => {

        var input = [...tableRow]
        input[index].key = index
        input[index].product_batch_id = id
        var stock = responseJson.pb_stock / responseJson.pb_baseqty
        input[index].pb_stock = parseInt(stock)
        setbaseQty(responseJson.pb_baseqty)
        console.log("pei_image", responseJson.pei_image)
        
        input[index].sd_qty = 1
        input[index].unit = responseJson.unit_code
        input[index].color = responseJson.color_name
        input[index].size = responseJson.size_name
        input[index].pei_image = responseJson.pei_image
        
        input[index].price = responseJson.pb_salesrate
        input[index].calcprice = responseJson.pb_salesrate
        input[index].discount = 0

        input[index].sd_total_amount = responseJson.pb_salesrate 

            
            
            console.log("input", input)
            setTableRow(input)
            setTableRow([...tableRow, {key:"",product_batch_id:"",product_id: "", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, pb_stock: "", sales_iscombo: "",sod_id: "",color: "", size: "",colorsize: [],  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
            setCode("")
            
            // var total1 = total + responseJson.pb_salesrate
            // console.log("totalcalc", total1)
            // setTotal(total1)
    }

    const cbproductChange = (event, index) => {
        const input = [...combotable]
        input[index].productid = event.target.value
        setCombotable(input)
        getBatchlist(event.target.value,index)
    }

    const getBatchlist = (id, index) => {
        console.log("id", id)
        console.log("id", combopopupid)
          var data = {
              "products_id" : id,
              "products_id_master" : combopopupid
          }
          console.log("data", data)
          fetch(global.url + "getProductbyIdData", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("product list", responseJson)
              var input = [...combobatchlist]
              input[index].value = responseJson.data
              setCombobatchlist(input)
  
              var comborow = [...combotable]
              comborow[index].product_name = responseJson.master.product_name
              comborow[index].fetch_quantity = 1
              comborow[index].quantity = responseJson.master.fc_product_quantity
              setCombotable(comborow)
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    const cbbatchChange = (event, index) => {
        const input = [...combotable]
        input[index].fetch_id = event.target.value
        console.log("bachid", event.target.value)
        setCombotable(input)
        getProductbybatch(event.target.value, index)
      }

      const getProductbybatch = (id, index) => {
   
        var data = {
            "pb_id" : id,
        }
        console.log("data", data)
        fetch(global.url + "getProductBatchById", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
  
            var comborow = [...combotable]
            comborow[index].color = responseJson.data.color_name
            comborow[index].size = responseJson.data.size_name
            comborow[index].stock = responseJson.data.pb_stock
            setCombotable(comborow)
            
        })
        .catch((error)=>{
            console.log(error)
        })
      }
      const addComborow = () => {
        setCombotable([...combotable, {key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "",  stock: ""}])
        setCombobatchlist([...combobatchlist, {key: "", value: []}])
    }

    const addtoTablerow = () => {
        var counts = [];
        const exampleArray = []
        
            for(var i = 0 ; i < combotable.length; i++) {
                exampleArray.push(combotable[i].productid)
            }
    
            for(var i = 0 ; i < combotable.length; i++) {
                counts.push({value: "", count: 0})
                var input = [...counts]
                input[i].value = combotable[i].productid
                exampleArray.forEach((v) => (v === combotable[i].productid && counts[i].count++ ));
            }
            
            for(var i = 0 ; i < counts.length; i++) {
                for(var j = 0 ; j < combotable.length; j++) {
                    
                    if(counts[i].value == combotable[j].productid){
                        console.log("if", i)
                        if(counts[i].count > combotable[j].quantity){
                            console.log("2if")
                            alert("greater than max quantity - " + combotable[j].product_name)
                            return;
                        }
                        if(counts[i].count < combotable[j].quantity){
                            console.log("2if")
                            alert("Lesser than max quantity - " + combotable[j].product_name)
                            return;
                        }
                    }
                    
                }
            }
            console.log("combotable", combotable)
            console.log("exampleArray", exampleArray)

            if(exampleArray.length == 0){
                alert("No Products added")
            }else{
                var input = [...tableRow]
                console.log("combotable",combotable )
                input[popupindex].fetch_products = combotable
                setTableRow(input)
                console.log("tableRow",tableRow )
                setModal2(false)
                setCombotable([{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "",  stock: ""}])
            }
        
    }

    const addProduct = (id, index, comborows, comboid) => {
        console.log("popupid", comboid)
        setModal2(true)
        setCombopopupid(comboid)
        setPopupindex(index)
        var data = {
            "products_id" : id,
        }
        console.log("data", data)
        fetch(global.url + "getProductbyFetchID", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("product list", responseJson)
            setComboproductlist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
        setCombotable(comborows)
    }

    const recallClick = () => {
        setRecall(true)
        getRecalllist()
    }

    const getRecalllist = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var data = {
            "userid" : userdata.user_id,
        }
        console.log("data", data)
        fetch(global.url + "posListDiscard", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("recall list", responseJson)
            setInvoicelist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getRecall = (id) => {
        setRecall(false)
        var data = {
            "sales_draft_id" : id,
        }
        console.log("data", data)
        fetch(global.url + "posDiscardDetail", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("recall", responseJson)
            
                setTableRow(responseJson.data.details)
         
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    return(
        <FullScreen handle={handle}>
        <div className="main-content">
           
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20}}>
            {/* <div style={{background: '#6d37b9', width: '100%', padding: 5, display: 'flex'}}>
                <p style={{fontSize:20, color: '#fff', marginBottom: 0}}>POS</p>
            </div> */}
               <div>
                   <Row>
                       <Col xs={12} className="border-color">
                            <p  onClick={handle.enter} style={{textAlign: 'center', marginTop: 15}}>Dekanet Beirut</p>
                        </Col>
                    </Row>
                    
                   <Row style={{marginTop: 30}}>
                       <Col sm={7}>
                           <Row>
                                {/* <Col sm={2}>
                                    <p>Bill Type</p>
                                </Col> */}
                                <Col sm={4}>
                                    <FormControl sx={{  width: '100%' }}>
                                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Bill Type</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={billType}
                                                label="Age"
                                                onChange={billtypeChange}
                                                size="small"
                                                
                                            >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {billtypelist.map((row, index) => {
                                        return (
                                            <MenuItem value={row.billset_id}>{row.billset_formtype}</MenuItem>
                                        )})}
                                            </Select>
                                    </FormControl>
                                </Col>
                                <Col xs={12} sm={4} className="retail-top">
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField disabled value={invoiceno} id="outlined-basic" variant="outlined"  size="small" label="Bill #"/>
                                        </FormControl>
                                </Col>
                                <Col xs={12} lg={4}>
                                        <LocalizationProvider sx={{width: '100%'}} dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Date"
                                                value={date}
                                                onChange={(newValue) => {
                                                    setDate(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                </Col>
                                <Col xs={12} sm={4} className="retail-top">
                                        <FormControl sx={{width: '100%',  marginTop: 2  }}>
                                            <TextField disabled value={username} id="outlined-basic" variant="outlined"  size="small" label="User"/>
                                        </FormControl>
                                </Col>
    
    
                                <Col xs={12} sm={4} className="retail-top" >
                                        <FormControl sx={{width: '100%', marginTop: 2 }}>
                                            <TextField disabled value={startShit} id="outlined-basic" variant="outlined"  size="small" label="Shift Start"/>
                                        </FormControl>
                                </Col>
                                <Col xs={12} sm={4} className="retail-top" >
                                        <FormControl sx={{width: '100%', marginTop: 2  }}>
                                            <TextField disabled value={endShift} id="outlined-basic" variant="outlined"  size="small" label="Shift End"/>
                                        </FormControl>
                                </Col>
                                <Col xs={12} sm={4} className="retail-top">
                                        <FormControl sx={{width: '100%',  marginTop: 2  }}>
                                            <TextField type='number' value={mobile} onChange={(e) => setMobile(e.target.value)} id="outlined-basic" variant="outlined"  size="small" label="Mobile"/>
                                        </FormControl>
                                </Col>

                                {/* <Col xs={12} sm={4} className="retail-top">
                                    <FormControl sx={{width: '100%', marginTop: 2 }}>
                                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Discard Item</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={discard}
                                                    label="Age"
                                                    onChange={discardChange}
                                                    size="small"
                                                >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {discardlist.map((row, index) => {
                                                    return (
                                                    <MenuItem value={row.sales_draft_id}>{row.sales_draft_invoice}</MenuItem>
                                                )})}
                                                </Select>
                                        </FormControl>
                                </Col> */}
                            </Row>
                            <Row>
                                <Col xs={5}>
                                    <FormControl sx={{ mt: 4, width: '100%' }}>
                                        <FormControlLabel control={
                                        <Checkbox checked={blocked == 0? false : true} onChange={e => checkChange(e)} />
                                        } label="Combo" />
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 20}} className="tableheight">
                            <Table bordered responsive >
                                <thead>
                                    <tr>
                                        <th>SlNo</th>
                                        <th>Code</th>
                                        {/* <th>color-size</th> */}
                                        <th>Product name</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                        {/* <th>Calc Price</th> */}
                                        <th>Amount</th>
                                        <th>Close</th>
                                    </tr>
                                </thead>

                                {/* <tbody>
                                    {tableRow.map((item, index) => {
                                        return (
                                            <tr>
                                                <td >{index+1}</td>
                                                <td>
                                                   {blocked == 1 ? 
                                                   <input autocomplete="off" ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} />:
                                                   <input autocomplete="off" ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "intercode")} />
                                                } 
                                                
                                                {hasResults && selectedIndex == index && (
                                                        <div className="autocomplete">
                                                            <ul ref={resultsRef} className="people scrolling-div">
                                                            {productlist.map(item => {
                                                                    return (
                                                                        item.product_fetchiscombo == 0 ? 
                                                                        item.products.map(result => {
                                                                            return (
                                       
                                                                            <li key={item.url}>
                                                                                <a href="javascript:void(0)" onClick={() => listClickrefcode(
                                                                                    index, 
                                                                                    item.products_id,
                                                                                    item.product_refcode,
                                                                                    item.product_name,
                                                                                    item.products,
                                                                                    item.pei_image,
                                                                                    0
                                                                                    )}>
                                                                                {item.product_refcode} - { item.product_name }
                                                                                </a>
                                                                            </li>
                                                                        )
                                                                        }) : 
                                                                        <li key={item.url}>
                                                                            <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                                index, 
                                                                                item.products_id,
                                                                                item.product_refcode,
                                                                                item.product_name,
                                                                                item.pricelevel_name,
                                                                                "1",
                                                                                item.unit_name,
                                                                                item.product_comboprice,
                                                                                item.product_comboprice,
                                                                                0,
                                                                                item.product_comboprice,
                                                                                item.pricelevel_percentage,
                                                                                item.product_combostock,
                                                                                1
                                                                                )}>
                                                                            {item.product_refcode} - { item.product_name }
                                                                            </a>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    )}
                                                    </td>
                                                <td>
                                                    {item.productname}
                                                </td>
                                                <td>{item.unit}</td>
                                                <td>{item.sd_qty}</td>
                                                <td>{item.price}</td>
                                                <td>{item.sd_total_amount}</td>
                                                <td><Button onClick={() => cancelItemclick(index, item.sd_total_amount)} style={{backgroundColor: '#fff', border: 'none', boxShadow: 'none'}} variant="contained">< AiFillMinusCircle color="#6d37b9" fontSize={30} style={{marginRight: 10}} /></Button></td>
                                            </tr>
                                        )
                                    })}
                                    
                                </tbody> */}

                                <tbody>
                                    {tableRow.map((item, index) => {
                                        return (
                                            <tr  style={item.sod_iscancel == 1 ? {background: 'red'}: null}>
                                                <td >{index+1}</td>
                                                <td>
                                                {blocked == 1 ?
                                                    <input autocomplete="off" ref={inputRef} type="text" name="query" value={item.pb_inter_barcode} onChange={(event) => handleOnChange(event, index, "refcode")} />:
                                                    <input autocomplete="off" ref={inputRef} type="text" name="query" value={item.pb_inter_barcode} onChange={(event) => handleOnChange(event, index, "intercode")} />
                                                }
                    
                                                    {hasResults && selectedIndex == index && (
                                                        <div className="autocomplete">
                                                            <ul ref={resultsRef} className="people scrolling-div">
                                                                {productlist.map(item => {
                                                                    return (
                                                                        item.product_fetchiscombo == 0 ? 
                                                                        item.products.map(result => {
                                                                            return (
                                                                            <li key={item.url}>
                                                                                <a href="javascript:void(0)" onClick={() => listClickrefcode(
                                                                                    index,
                                                                                    item.pb_iseditprice, 
                                                                                    item.products_id,
                                                                                    item.pb_id,
                                                                                    item.product_refcode,
                                                                                    item.product_name,
                                                                                    item.products,
                                                                                    item.pei_image,
                                                                                    0
                                                                                    )}>
                                                                                {item.product_refcode} - { item.product_name }
                                                                                </a>
                                                                            </li>
                                                                        )
                                                                        }) : 
                                                                        <li key={item.url}>
                                                                            <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                                index, 
                                                                                item.products_id,
                                                                                item.product_refcode,
                                                                                item.product_name,
                                                                                item.pricelevel_name,
                                                                                "1",
                                                                                item.unit_name,
                                                                                item.product_comboprice,
                                                                                item.product_comboprice,
                                                                                0,
                                                                                item.product_comboprice,
                                                                                item.pricelevel_percentage,
                                                                                item.product_combostock,
                                                                                1
                                                                                )}>
                                                                            {item.product_refcode} - { item.product_name }
                                                                            </a>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    )}
                                                    </td>
                                                    {/* {
                                                        
                                                        item.addnew == true ? 
                                                      
                                                            <td>
                                                            <FormControl sx={{width: '100%' }}>
                                                                        <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Select</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-helper-label"
                                                                            id="demo-simple-select-helper"
                                                                            value={item.pb_color_fk}
                                                                            label="Age"
                                                                            // onChange={colorChange}
                                                                            size="small"
                                                                        >
                                                                        <MenuItem value="">
                                                                            <em>None</em>
                                                                        </MenuItem>
                                                                        {item.colorsize.map((row, index) => {
                                                                            return (
                                                                            <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                                                        )})}
                                                                        </Select>
                                                                </FormControl>
                                                                </td>
                                                            : 
                                                          
                                                            <td></td>
                                                    } */}
                                                    <td style={{fontSize: 10}}>

                                                        {item.sales_iscombo == 0 || item.sales_iscombo == null   ? 
                                                        
                                                        <p> {item.productname} - {item.color_name} - {item.size_name} </p> :
                                                                
                                                                item.sales_iscombo == 1 ?
                                                                <div>
                                                                    {
                                                                item.addnew == false ? null :
                                                                <Button style={{height: 50}} variant="contained" onClick={() => addProduct(item.product_batch_id, index, item.fetch_products, item.product_batch_id)}>Add Product</Button>
                                                                    }
                                                                    {
                                                        
                                                                        item.fetch_products.map((result, k) => {
                                                                        return(
                                                                            <label style={{fontSize: 10}}>
                                                                                {result.product_name} - {result.color_name} - {result.size_name}
                                                                            </label>
                                                                            )
                                                                        })
                                                                    }
                                                            
                                                                </div> : 
                                                                    null
                                                            }
                                                    </td>
                                                <td>
                                                    {item.sales_iscombo == 0 ||  item.sales_iscombo == null   ?
                                                        <div>  
                                                        {item.addnew == false ?
                                                        <input min="1" disabled  autocomplete="off" type="number" name="query" value={item.sd_qty} onChange={(event) => qtyChange(event, index)} />: 
                                                        <input min="1"  autocomplete="off" type="number" name="query" value={item.sd_qty} onChange={(event) => qtyChange(event, index)} />
                                                        }
                                                        {
                                                            <label style={{fontSize: 12, fontWeight: 'bold', color: 'red'}}>stock: {item.pb_stock}</label>
                                                        }
                                                    </div>: 
                                                    null
                                                    }
                                                </td>
                                                {editprice == 1 ? 
                                                <td>
                                                     <input min="1" autocomplete="off" type="number" name="query" value={item.price} onChange={(event) => priceChange(event, index)} />
                                                </td>:
                                                <td>{item.price}</td>}
                                                {/* {item.product_fetchiscombo == 0 ?
                                                    <td>{item.calcprice}</td> :
                                                    <td>{item.price}</td>
                                                } */}

                                                <td>{item.sd_total_amount}</td>
                                                <td>
                                                {item.sod_iscancel == 1 ? null : item.sales_isedit == 1 ? null: <Button onClick={() => deleteRow(index, item.amount, item.sod_id)} style={{backgroundColor: '#fff', border: 'none', boxShadow: 'none'}} variant="contained">< AiFillMinusCircle color="#6d37b9" fontSize={30} style={{marginRight: 10}} /></Button>}</td>
                                            </tr>
                                        )
                                    })}
                                    
                                </tbody>

                            </Table>
                            <Button onClick={() => addRow()} style={{float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20, marginTop: 20, width: 150}} variant="contained">Add New</Button>
                            </Row>
                            <Row style={{marginTop: 50}}>
                                <Col sm={3}>
                                    <p>Bill Amount</p>
                                    <p>Card</p>
                                    <p>Balance</p>
                                    <p>Payable</p>
                                </Col>
                                <Col sm={3}>
                                    <div className="textbox-padding">
                                        <FormControl sx={{width: '100%' }} style={{marginTop: 5}}>
                                            <TextField value={billamount.toFixed(2)} disabled  id="outlined-basic" variant="outlined"  size="small"  />
                                        </FormControl>
                                    </div>
                                    <div className="textbox-padding" style={{marginTop: 15}}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField  id="outlined-basic" value={card} disabled variant="outlined"  size="small"  />
                                        </FormControl>
                                    </div>
                                    <div className="textbox-padding" style={{marginTop: 15}}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField  id="outlined-basic" value={balance.toFixed(2)} disabled variant="outlined"  size="small"  />
                                        </FormControl>
                                    </div>
                                    <div className="textbox-padding">
                                        <FormControl sx={{width: '100%' }} style={{marginTop: 15}}>
                                            <TextField  id="outlined-basic" disabled value={ payable.toFixed(2)}  variant="outlined"  size="small"  />
                                        </FormControl>
                                    </div>
                                </Col>


                                <Col sm={2}>
                                    <p>Discount</p>
                                    <p>Cash</p>
                                    {/* <p>Payment RCVD</p> */}
                                </Col>
                                <Col sm={2}>
                                    <div className="textbox-padding" style={{display: 'flex'}}>
                                        <FormControl sx={{width: '100%' }} style={{marginTop: 5}}>
                                            <TextField  id="outlined-basic" value={discountpercentage}  onChange={(e) => setDiscountpercentage(e.target.value)} variant="outlined"  size="small"  />
                                        </FormControl>
                                        <p style={{marginBottom: 'unset', marginTop: 2, marginLeft: 2}}>%</p>
                                    </div>
                                </Col>
                                <Col sm={2}>
                                    <div className="textbox-padding">
                                        <FormControl sx={{width: '100%' }} style={{marginTop: 5}}>
                                            <TextField type='number'  id="outlined-basic" value={discountamound}  onChange={(e) => setDiscountamound(e.target.value)} variant="outlined"  size="small"  />
                                        </FormControl>
                                    </div>
                                    <div className="textbox-padding">
                                        <FormControl sx={{width: '100%' }} style={{marginTop: 15}}>
                                            <TextField value={cash} id="outlined-basic" disabled variant="outlined"  size="small"  />
                                        </FormControl>
                                    </div>
                                    {/* <div className="textbox-padding" style={{marginTop: 15}}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField value={clickedamt} disabled  id="outlined-basic" variant="outlined"  size="small"  />
                                        </FormControl>
                                    </div> */}
                                </Col>
                            </Row>
                       </Col>
                       <Col sm={3}>
                                {/* <Row>
                                    <Col sm={8}  className="textbox-padding">
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField  id="outlined-basic" variant="outlined"  size="small" placeholder="Barcode" />
                                        </FormControl>
                                    </Col>
                                    <Col sm={4}  className="textbox-padding">
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField  id="outlined-basic" variant="outlined"  size="small" placeholder="Code" />
                                        </FormControl>
                                    </Col>
                               </Row> */}
                               <Row style={{marginTop:10}}>
                                    <Col sm={4}  className="textbox-padding">
                                        <Button  onClick={()=> minusqty()} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">-</Button>
                                    </Col>
                                    <Col sm={4}  className="textbox-padding">
                                        <FormControl sx={{width: '100%', marginTop: 1 }}>
                                            <TextField  id="outlined-basic" variant="outlined" value={qty} onChange={(e) => setQty(e.target.value)}  size="small" placeholder="QTY" />
                                        </FormControl>
                                    </Col>
                                    <Col sm={4}  className="textbox-padding">
                                        <Button onClick={()=> plusQty()} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">+</Button>
                                    </Col>
                               </Row>
                               <Row style={{marginTop: 10}}>
                                    <Col xs={4}>
                                        <Button onClick={() => setQty(7)} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">7</Button>
                                    </Col>
                                    <Col xs={4}>
                                        <Button  onClick={() => setQty(8)} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">8</Button>
                                    </Col>
                                    <Col xs={4}>
                                        <Button onClick={() => setQty(9)} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">9</Button>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: 10}}>
                                    <Col xs={4}>
                                        <Button onClick={() => setQty(4)} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">4</Button>
                                    </Col>
                                    <Col xs={4}>
                                        <Button onClick={() => setQty(5)} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">5</Button>
                                    </Col>
                                    <Col xs={4}>
                                        <Button onClick={() => setQty(6)} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">6</Button>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: 10}}>
                                    <Col xs={4}>
                                        <Button onClick={() => setQty(1)} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">1</Button>
                                    </Col>
                                    <Col xs={4}>
                                        <Button onClick={() => setQty(2)} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">2</Button>
                                    </Col>
                                    <Col xs={4}>
                                        <Button onClick={() => setQty(3)} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">3</Button>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: 10}}>
                                    <Col xs={4}>
                                        <Button onClick={() => setQty(0)} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">0</Button>
                                    </Col>
                                    <Col xs={4}>
                                        <Button  style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">.</Button>
                                    </Col>
                                    <Col xs={4}>
                                        <Button  onClick={() => setQty(1)} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">Clear</Button>
                                    </Col>
                                </Row>
                                

                                <Row style={{marginTop: 20}}>
                                    <Col>
                                        <Button onClick={() => cashbuttonClick()} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold', marginTop: 10}} variant="contained">CASH</Button>
                                        <Button onClick={() => bankcashbuttonClick()} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5,  fontWeight: 'bold', marginTop: 10}} variant="contained">BANK/CASH</Button>
                                        <Button onClick={() => bankbuttonClick()} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5, fontWeight: 'bold', marginTop: 10}} variant="contained">BANK</Button>
                                    </Col>
                                </Row>
                                {cashbutton == true ?
                                <Row>    
                                
                                <Row>
                                    <Col xs={6}>
                                        <FormControl sx={{ mt: 4, width: '100%' }}>
                                            <TextField  value={clickedamt} disabled id="outlined-basic" label="Cash Received" variant="outlined"  size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={6}>
                                        <FormControl sx={{ mt: 4, width: '100%' }}>
                                            <TextField disabled value={totalbalance} id="outlined-basic" label="Balance return to customer" variant="outlined"  size="small" />
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: 20}}>
                                    <Col>
                                        <Button onClick={() => calculate(500)} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">500</Button>
                                        <Button onClick={() => calculate(200)} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5,  fontWeight: 'bold'}} variant="contained">200</Button>
                                        <Button onClick={() => calculate(100)} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5, fontWeight: 'bold'}} variant="contained">100</Button>
                                        <Button onClick={() => calculate(50)} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5, fontWeight: 'bold'}} variant="contained">50</Button>
                                        <Button onClick={() => calculate(10)} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5, marginTop: 10, fontWeight: 'bold'}} variant="contained">10</Button>
                                        <Button onClick={() => calculate(5)} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5, marginTop: 10, fontWeight: 'bold'}} variant="contained">5</Button>
                                        <Button onClick={() => calculate(1)} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5, marginTop: 10, fontWeight: 'bold'}} variant="contained">1</Button>
                                        <Button onClick={() => calculate()} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5, marginTop: 10, fontWeight: 'bold'}} variant="contained">Clear</Button>
                                    </Col>
                                </Row>
                                <Col xs={6}>
                                    <FormControl sx={{ mt: 4, width: '100%' }}>
                                        <TextField onChange={(e) => setCash(e.target.value)} value={cash} id="outlined-basic" label="Cash" variant="outlined"  size="small" />
                                    </FormControl>
                                </Col>
                                <Col xs={6}>
                                    <FormControl sx={{ mt: 4, width: '100%' }}>
                                        <TextField value={cashtotal}  disabled id="outlined-basic" label="Total" variant="outlined"  size="small" />
                                    </FormControl>
                                </Col>

                                <Col xs ={12} style={{marginTop:30}}>
                                    <Button onClick={() => onSave()} style={{float: 'right'}} variant="contained">Save</Button>
                                </Col>
                            
                        </Row> :
                        bankbutton == true ?
                        <Row>    
                                
                       
                            
                        <Col xs={6}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField onChange={(e) => setCard(e.target.value)} value={card} id="outlined-basic" label="Card" variant="outlined"  size="small" />
                            </FormControl>
                        </Col>
                        <Col xs={6}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField onChange={(e) => setRefno(e.target.value)} value={refno}  id="outlined-basic" label="Ref No" variant="outlined"  size="small" />
                            </FormControl>
                        </Col>
                       
                        <Col xs={6}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField value={cashtotal}  disabled id="outlined-basic" label="Total" variant="outlined"  size="small" />
                            </FormControl>
                        </Col>

                        <Col xs ={12} style={{marginTop:30}}>
                            <Button onClick={() => onSave()} style={{float: 'right'}} variant="contained">Save</Button>
                        </Col>
                    
                </Row> :
                <Row>    
                                
                        <Row>
                            <Col xs={6}>
                                <FormControl sx={{ mt: 4, width: '100%' }}>
                                    <TextField  value={clickedamt} disabled id="outlined-basic" label="Cash Received" variant="outlined"  size="small" />
                                </FormControl>
                            </Col>
                            <Col xs={6}>
                                <FormControl sx={{ mt: 4, width: '100%' }}>
                                    <TextField disabled value={totalbalance} id="outlined-basic" label="Balance return to customer" variant="outlined"  size="small" />
                                </FormControl>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 20}}>
                            <Col>
                                <Button onClick={() => calculate(500)} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">500</Button>
                                <Button onClick={() => calculate(200)} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5,  fontWeight: 'bold'}} variant="contained">200</Button>
                                <Button onClick={() => calculate(100)} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5, fontWeight: 'bold'}} variant="contained">100</Button>
                                <Button onClick={() => calculate(50)} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5, fontWeight: 'bold'}} variant="contained">50</Button>
                                <Button onClick={() => calculate(10)} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5, marginTop: 10, fontWeight: 'bold'}} variant="contained">10</Button>
                                <Button onClick={() => calculate(5)} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5, marginTop: 10, fontWeight: 'bold'}} variant="contained">5</Button>
                                <Button onClick={() => calculate(1)} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5, marginTop: 10, fontWeight: 'bold'}} variant="contained">1</Button>
                                <Button onClick={() => calculate()} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5, marginTop: 10, fontWeight: 'bold'}} variant="contained">Clear</Button>
                            </Col>
                        </Row>
                        <Col xs={6}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField onChange={(e) => setCard(e.target.value)} value={card} id="outlined-basic" label="Card" variant="outlined"  size="small" />
                            </FormControl>
                        </Col>
                        <Col xs={6}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField onChange={(e) => setRefno(e.target.value)} value={refno}  id="outlined-basic" label="Ref No" variant="outlined"  size="small" />
                            </FormControl>
                        </Col>
                        <Col xs={6}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField onChange={(e) => setCash(e.target.value)} value={cash} id="outlined-basic" label="Cash" variant="outlined"  size="small" />
                            </FormControl>
                        </Col>
                        <Col xs={6}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField value={cashtotal}  disabled id="outlined-basic" label="Total" variant="outlined"  size="small" />
                            </FormControl>
                        </Col>

                        <Col xs ={12} style={{marginTop:30}}>
                            <Button onClick={() => onSave()} style={{float: 'right'}} variant="contained">Save</Button>
                        </Col>
                    
                    </Row>
                        }
                                
                        </Col>
                        <Col sm={2}>
                           
                                <Button onClick={() => lookupClick()} className="retail-btn" style={{backgroundColor: '#6d37b9', borderRadius: 20, marginTop: 10, width: '100%'}} variant="contained">Item Lookup</Button>
                                {/* <Button onClick={() => cancelItemclick()} className="retail-btn" style={{backgroundColor: '#6d37b9', borderRadius: 20, marginTop: 10, width: '100%'}} variant="contained">Cancel Item</Button> */}
                                {/* <Button className="retail-btn" style={{backgroundColor: '#6d37b9', borderRadius: 20, marginTop: 10,width: '100%'}} variant="contained">Cancel Invoice</Button> */}
                                <Button onClick={() => suspendClick()} className="retail-btn" style={{backgroundColor: '#6d37b9', borderRadius: 20, marginTop: 10,width: '100%'}} variant="contained">Suspend</Button>
                                <Button onClick={ () => recallClick()} className="retail-btn" style={{backgroundColor: '#6d37b9', borderRadius: 20, marginTop: 10,width: '100%'}} variant="contained">Recall</Button>
                                <Button onClick={() => returnClick()}  className="retail-btn" style={{backgroundColor: '#6d37b9', borderRadius: 20, marginTop: 10,width: '100%'}} variant="contained">Return</Button>
                                {/* <Button className="retail-btn" style={{backgroundColor: '#6d37b9', borderRadius: 20, marginTop: 10,width: '100%'}} variant="contained">Drawer Open</Button> */}
                                {/* <a href="/Sales/possummary"> */}
                                <Button onClick={() => returnClick()}  className="retail-btn" style={{backgroundColor: '#6d37b9', borderRadius: 20, marginTop: 10,width: '100%'}} variant="contained">Reprint</Button>
                                {/* </a> */}
                                <Button className="retail-btn" style={{backgroundColor: '#6d37b9', borderRadius: 20, marginTop: 10,width: '100%'}} variant="contained">Logout</Button>
                                {/* <Button className="retail-btn" style={{backgroundColor: '#6d37b9', borderRadius: 20, marginTop: 10,width: '100%'}} variant="contained">More ...</Button> */}
                        </Col>

                        
                        
                   </Row>

                   
                </div>
            </div>
          </Paper> 
         
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Pay mode
                    </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={2}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                                <FormControlLabel control={
                                <Checkbox checked={cash == 0? false : true} onChange={e => cashChange(e)} />
                                } label="Cash" />
                        </FormControl>
                    </Col>
                    <Col xs={2}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                                <FormControlLabel control={
                                <Checkbox checked={bank == 0? false : true} onChange={e => bankChange(e)} />
                                } label="Bank" />
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    {cash == 1 ? 
                        <Col xs={6} style={{marginTop: 30}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => setCashamount(e.target.value)} value={cashamount} id="outlined-basic" variant="outlined"  size="small" label="Cash amount"/>
                            </FormControl>
                        </Col>: null
                    }
                    {bank == 1 ? 
                        <Col xs={6} style={{marginTop: 30}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => setBankamount(e.target.value)} value={bankamount} id="outlined-basic" variant="outlined"  size="small" label="Product code"/>
                            </FormControl>
                        </Col>: null
                    }
                   
                    <Col xs={6} style={{marginTop: 30}}>
                        <Button onClick={() => onSave()} className="retail-btn" style={{backgroundColor: '#6d37b9', borderRadius: 20}} variant="contained"><HiSave  color="White" fontSize={20} style={{marginRight: 10}} />Save</Button>
                    </Col>
                </Row>
              </Modal.Body>
           </Modal>

           {/* productmodal */}
           <Modal
              size="lg"
              show={productmodal}
              onHide={() => setProductModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Product List
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                      <Col xs={12} md={5}>
                            <TextField onChange={(event) => onfilter(event.target.value, "intercode")} id="outlined-basic" variant="outlined"  size="small" label="Barcode"/>
                      </Col>
                      <Col xs={12} md={5}>
                            <TextField onChange={(event) => onfilter(event.target.value, "name")} id="outlined-basic" variant="outlined"  size="small" label="Product Name"/>
                      </Col>
                      {/* <Button onClick={()=> lookupClick()} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">Refresh</Button> */}
                  </Row>
                  <Row style={{marginTop: 20}}>
                    <Col xs={12} >
                    <Table striped bordered hover >
                        <thead>
                            <tr>
                                <th>Product Name</th>
                                <th>Barcode</th>
                                <th>Stock</th>
                                <th>Sales Price</th>
                            </tr>
                        </thead>
                        {/* {productlistitem.length == 0 ? */}
                            <tbody>
                                {lookupproductlist.map((column) => (
                                    <tr>
                                        <td>{column.product_name}</td>
                                        <td>{column.pb_inter_barcode}</td>
                                        <td>{column.pb_stock}</td>
                                        <td>{column.pb_salesrate}</td>
                                    </tr>
                                    ))
                                } 
                            </tbody>
                            {/* <tbody>
                                {productlistitem.map((column) => (
                                    <tr>
                                        <td>{column.product_name}</td>
                                        <td>{column.pb_inter_barcode}</td>
                                        <td>{column.pb_stock}</td>
                                        <td>{column.pb_salesrate}</td>
                                    </tr>
                                    ))
                                } 
                            </tbody> */}
                        {/* }
                        {productlistitem.length == 0 ?
                            <tbody>
                                {lookupproductlist.length == 0}
                                {lookupproductlist.map((column) => (
                                    <tr>
                                        <td>{column.product_name}</td>
                                        <td>{column.pb_inter_barcode}</td>
                                        <td>{column.pb_stock}</td>
                                        <td>{column.pb_salesrate}</td>
                                    </tr>
                                    ))
                                } 
                            </tbody>:
                            <tbody>
                                {productnameitem.map((column) => (
                                    <tr>
                                        <td>{column.product_name}</td>
                                        <td>{column.pb_inter_barcode}</td>
                                        <td>{column.pb_stock}</td>
                                        <td>{column.pb_salesrate}</td>
                                    </tr>
                                    ))
                                } 
                            </tbody>
                        } */}
                        </Table>
                        
                    </Col>
                  </Row>
              </Modal.Body>
           </Modal>

            {/* authmodal */}
           <Modal
              size="md"
              show={authmodal}
              onHide={() => setAuthmodal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                Authentication
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                        <Col xs={12} >
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField onChange={(e) => setUsername(e.target.value)} value={username} id="outlined-basic" label="Username" variant="outlined"  size="small" />
                            </FormControl>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField onChange={(e) => setPassword(e.target.value)} value={password} id="outlined-basic" label="Password" variant="outlined"  size="small" />
                            </FormControl>
                        </Col>

                        <Col xs ={12} style={{marginTop:30}}>
                            <Button  style={{float: 'right'}} variant="contained">Submit</Button>
                            <Button style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                        </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>

           {/* card popup */}
           <Modal
              size="md"
              show={cardmodal}
              onHide={() => setCardmodal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                Payment
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                        {/* <Col xs={12}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Payment Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={paymenttype}
                                        label="Age"
                                        onChange={paymenttypeChange}
                                        size="small"
                                        
                                    >
                                    <MenuItem value="">
                                            <em>None</em>
                                    </MenuItem>
                                        
                                    <MenuItem value={""}>Visa</MenuItem>
                                    <MenuItem value={""}>Mastercard</MenuItem>
                                
                                        
                                    </Select>
                            </FormControl>
                        </Col> */}
                        <Row>
                        <Col xs={6}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField onChange={(e) => setCard(e.target.value)} value={card} id="outlined-basic" label="Cash Received" variant="outlined"  size="small" />
                            </FormControl>
                        </Col>
                        <Col xs={6}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField onChange={(e) => setCash(e.target.value)} value={cash} id="outlined-basic" label="Balance return to customer" variant="outlined"  size="small" />
                            </FormControl>
                        </Col>
                        </Row>
                                <Row style={{marginTop: 20}}>
                                    <Col>
                                        <Button onClick={() => calculate(500)} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">500</Button>
                                        <Button onClick={() => calculate(200)} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5,  fontWeight: 'bold'}} variant="contained">200</Button>
                                        <Button onClick={() => calculate(100)} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5, marginTop: 10, fontWeight: 'bold'}} variant="contained">100</Button>
                                        <Button onClick={() => calculate(50)} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5, marginTop: 10, fontWeight: 'bold'}} variant="contained">50</Button>
                                        <Button onClick={() => calculate(10)} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5, marginTop: 10, fontWeight: 'bold'}} variant="contained">10</Button>
                                        <Button onClick={() => calculate(5)} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5, marginTop: 10, fontWeight: 'bold'}} variant="contained">5</Button>
                                        <Button onClick={() => calculate(1)} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5, marginTop: 10, fontWeight: 'bold'}} variant="contained">1</Button>
                                        <Button onClick={() => calculate()} style={{backgroundColor: '#fff', color: '#6d37b9', marginLeft: 10, borderRadius: 5, marginTop: 10, fontWeight: 'bold'}} variant="contained">Clear</Button>
                                    </Col>
                                </Row>
                        <Col xs={6}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField onChange={(e) => setCard(e.target.value)} value={card} id="outlined-basic" label="Card" variant="outlined"  size="small" />
                            </FormControl>
                        </Col>
                        <Col xs={6}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField onChange={(e) => setRefno(e.target.value)} value={refno}  id="outlined-basic" label="Ref No" variant="outlined"  size="small" />
                            </FormControl>
                        </Col>
                        <Col xs={6}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField onChange={(e) => setCash(e.target.value)} value={cash} id="outlined-basic" label="Cash" variant="outlined"  size="small" />
                            </FormControl>
                        </Col>
                        <Col xs={6}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField value={cashtotal}  disabled id="outlined-basic" label="Total" variant="outlined"  size="small" />
                            </FormControl>
                        </Col>

                        <Col xs ={12} style={{marginTop:30}}>
                            <Button onClick={() => setCardmodal(false)} style={{float: 'right'}} variant="contained">Submit</Button>
                            <Button onClick={() => setCardmodal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                        </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
           {/* recall */}

           <Modal
              size="lg"
              show={recall}
              onHide={() => setRecall(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Recall
                    </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  
                <Row>
                    <Col xs={12}>
                        <ul>
                        {invoicelists.map((row, index) => {
                            return (
                                <a style={{textDecoration: 'none', listStyle: 'none'}} href="javascript:void(0)" onClick={() => getRecall(row.sales_draft_id)} >
                                    <li className="pay-lists" style={{color: '#000'}}>{row.sales_draft_invoice}</li>
                                </a>
                            )}
                            )
                            }
                        </ul>
                    </Col>
                    
                </Row>
              </Modal.Body>
           </Modal>

           <Modal
            size="lg"
            show={modal2}
            onHide={() => setModal2(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
               Select Combo product
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {combotable.map((item, index) => {
                    return (
                        <Row style={{marginTop: 20}}>
                            <Col xs={12} md={5}>
                                <FormControl sx={{width: '100%' }}>
                                        <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Product</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={item.productid}
                                            label="Age"
                                            onChange={(e) => cbproductChange(e,index)}
                                            size="small"
                                        >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {comboproductlist.map((row, index) => {
                                            return (
                                            <MenuItem value={row.products_id}>{row.product_name}</MenuItem>
                                        )})}
                                        </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={3}>
                                <FormControl sx={{width: '100%' }}>
                                        <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Color and size</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={item.pb_id}
                                            label="Age"
                                            onChange={(e) => cbbatchChange(e,index)}
                                            size="small"
                                        >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {combobatchlist[index].value.map((row, index) => {
                                            return (
                                            <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                        )})}
                                        </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2}>
                               <label style={{fontSize: 10, color: 'red'}}>Max Quantiy = {item.quantity}</label>
                               <label style={{fontSize: 10, color: 'red'}}>stock = {item.stock}</label>
                            </Col>
                            <Col xs={12} md={2}>
                                    <a href="javascript:void(0)" onClick={() => deletePopupitem(index)}>
                                            <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                    </a> 
                            </Col>
                            
                        </Row>
                    )
                })
            }
            <Row style={{marginTop: 20}}>
                <Col xs={12} md={2}>
                    <Button style={{height: 30}} variant="contained" onClick={() => addComborow()}>Add</Button>
                </Col>
            </Row>
            <Row style={{marginTop: 20}}>
                <Col xs={12} md={2}>
                    <Button style={{height: 30}} variant="contained" onClick={() => addtoTablerow()}>Submit</Button>
                </Col>
            </Row>
            </Modal.Body>
        </Modal>

      </div>
      </FullScreen>
    )
}
export default Retailsale;
