import React, {useState, useEffect, useRef} from 'react';
import Paper from '@mui/material/Paper';
import { Table , Row, Col, Modal, Container} from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import { AiOutlineClose, AiFillMinusCircle } from "react-icons/ai";

function StockTransfer(props) {

    const inputRef = useRef();
    const resultsRef = useRef();
    const inputRef1 = useRef();

    const [billtypelist, setBilltypelist] = React.useState([]);
    const [billType, setBilltype] = React.useState('');
    const [invoice, setInvoice] = React.useState('');
    const [refno, setRefno] = React.useState('');
    const [remarks, setRemarks] = React.useState('');
    const [branchlist, setBranchlist] = useState([]);
    const [branch2, setBranch2] = useState("");
    const [date, setDate] = React.useState(new Date)
    const [addnew, setAddnew] = React.useState(false);
    const [tableRow,setTableRow] = React.useState([{key:"", batchid_from: "", batchid_to: "", stocktransfered: "", pbexp_id: '', product_batch_id:"",  product_batch_id2:"",product_id: "", code: "", code2 : "",  productname: "", productname2: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, sod_id: "",color: "", color2: "", size: "", size2: "", colorsize: [], colorsize2: [], addnew: true, fetch_products: [{key: "", productid: "", color: "", color2: "", size: "", size2: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: ""}] }])
    const [textArray, setTextarray] = React.useState([{key: "", value: ""}])
    const [selectedIndex, setSelectedindex] = React.useState("")
    const [code, setCode] = useState("");
    const [code2, setCode2] = useState("");
    const [productlist, setProductlist] = React.useState([]);
    const [productlist2, setProductlist2] = React.useState([]);
    const [productname, setProductname] = useState("")
    const [productname2, setProductname2] = useState("")
    const [customerpricelevel, setCustomerpricelevel] = React.useState("");
    const [total, setTotal] = React.useState(0);
    const [userId, setUserid] = React.useState("")
    const [errormsg, seterrormsg] = React.useState('');
    const [expirydatelist, setExpirydatelist] = React.useState([]);

    const hasResults = code.length > 0;
    const hasResults2 = code2.length > 0;
    const hasResultsProduct = productname.length > 0;
    const hasResultsProduct2 = productname2.length > 0;

    const billtypeChange = (event) => {
        setBilltype(event.target.value);
      };
    
      const branchselect = (event) => {
        setBranch2(event.target.value)
    }

    const colorChange = (event) => {
        var input=[...tableRow]
        console.log("selectedIndex", selectedIndex)
        input[selectedIndex].product_batch_id = event.target.value
        getExpiry(event.target.value)
        setTableRow(input)
        
        getProductbybatch1(event.target.value, selectedIndex)
      //   setColor(event.target.value)
    }

    const dateChange = (event) => {
        var input=[...tableRow]
        console.log("selectedIndex", selectedIndex)
        input[selectedIndex].pbexp_id = event.target.value
        setTableRow(input)
    }

    const colorChange2 = (event) => {
        var input=[...tableRow]
        console.log("selectedIndex", selectedIndex)
        input[selectedIndex].product_batch_id2 = event.target.value
        setTableRow(input)
        
        getProductbybatch2(event.target.value, selectedIndex)
      //   setColor(event.target.value)
    }

    const getExpiry = (id) => {
        var data = {
            "pb_id" : id,
        }
        console.log("data", data)
        fetch(global.url + "getExpiry", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("expiry", responseJson)
            setExpirydatelist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const setDetails = (id, index, responseJson) => {

        var input = [...tableRow]
        input[index].key = index
        input[index].product_batch_id = id
        input[index].batchid_from = id
        input[index].pb_stock = responseJson.pb_stock

        console.log("pei_image", responseJson.pei_image)
        
        input[index].sod_qty = 1
        input[index].stocktransfered = 1
        input[index].unit = responseJson.unit_code
        input[index].color = responseJson.color_name
        input[index].size = responseJson.size_name
        input[index].pei_image = responseJson.pei_image
        
        input[index].price = responseJson.pb_salesrate
        input[index].calcprice = responseJson.pb_salesrate
        input[index].discount = 0

        input[index].sod_total_amount = responseJson.pb_salesrate 

            
            
            console.log("input", input)
            setTableRow(input)
            // setTableRow([...tableRow, {key:"",product_batch_id:"",product_id: "", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, pb_stock: "", sales_iscombo: "",sod_id: "",color: "", size: "",colorsize: [],  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
            // setCode("")
            
            // var total1 = total + responseJson.pb_salesrate
            // console.log("totalcalc", total1)
            // setTotal(total1)
    }

    const setDetails2 = (id, index, responseJson) => {

        var input = [...tableRow]
        input[index].key = index
        input[index].product_batch_id2 = id
        input[index].batchid_to = id
        input[index].unit = responseJson.unit_code
        input[index].color2 = responseJson.color_name
        input[index].size2 = responseJson.size_name
        input[index].pei_image = responseJson.pei_image
        
        input[index].price = responseJson.pb_salesrate
        input[index].calcprice = responseJson.pb_salesrate
        input[index].discount = 0

        input[index].sod_total_amount = responseJson.pb_salesrate 

            
            
            console.log("input", input)
            setTableRow(input)
            // setTableRow([...tableRow, {key:"",product_batch_id:"",product_id: "", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, pb_stock: "", sales_iscombo: "",sod_id: "",color: "", size: "",colorsize: [],  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
            // setCode("")
            
            // var total1 = total + responseJson.pb_salesrate
            // console.log("totalcalc", total1)
            // setTotal(total1)
    }

    function onKeyDown1(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef1.current;
    
        const resultsItems = Array.from(resultsRef.current.children)
    
        const activeResultIndex = resultsItems.findIndex(child => {
          return child.querySelector('a') === document.activeElement;
        });
    
        if ( isUp ) {
          console.log('Going up!');
          if ( inputIsFocused ) {
            resultsItems[resultsItems.length - 1].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex - 1] ) {
            resultsItems[activeResultIndex - 1].querySelector('a').focus();
          } else {
            inputRef1.current.focus();
          }
        }
    
        if ( isDown ) {
          console.log('Going down!')
          if ( inputIsFocused ) {
            resultsItems[0].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex + 1] ) {
            resultsItems[activeResultIndex + 1].querySelector('a').focus();
          } else {
            inputRef1.current.focus();
          }
        }
      }

      function onKeyDown(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef.current;
    
        const resultsItems = Array.from(resultsRef.current.children)
    
        const activeResultIndex = resultsItems.findIndex(child => {
          return child.querySelector('a') === document.activeElement;
        });
    
        if ( isUp ) {
          console.log('Going up!');
          if ( inputIsFocused ) {
            resultsItems[resultsItems.length - 1].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex - 1] ) {
            resultsItems[activeResultIndex - 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
    
        if ( isDown ) {
          console.log('Going down!')
          if ( inputIsFocused ) {
            resultsItems[0].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex + 1] ) {
            resultsItems[activeResultIndex + 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
      }

    useEffect(() => {
        if ( hasResults ) {
          document.body.addEventListener('keydown', onKeyDown);
        } else {
          document.body.removeEventListener('keydown', onKeyDown);
        }

        if ( hasResults2 ) {
            document.body.addEventListener('keydown', onKeyDown);
          } else {
            document.body.removeEventListener('keydown', onKeyDown);
          }

        if ( hasResultsProduct ) {
            document.body.addEventListener('keydown', onKeyDown1);
        } else {
            document.body.removeEventListener('keydown', onKeyDown1);
        }

        if ( hasResultsProduct2 ) {
            document.body.addEventListener('keydown', onKeyDown1);
        } else {
            document.body.removeEventListener('keydown', onKeyDown1);
        }

        
        return () => {
          document.body.removeEventListener('keydown', onKeyDown);
          document.body.removeEventListener('keydown', onKeyDown1);
        }
      }, [hasResults, hasResults2, hasResultsProduct, hasResultsProduct2]);

      useEffect(() => {      
        getBilltype()
        getInvoiceno()
        getBranch()
      }, []);

      const getBranch = () => {
        var company = JSON.parse(localStorage.getItem("company"))
        const data = {
            "company_id": company
        }
        fetch(global.url + "viewAllBranch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("branch list response", responseJson)
            setBranchlist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const deleteRow = (index, price, sodid) => {
        var exarray = []
        for(var i = 0; i < tableRow.length; i++) {
            if(tableRow[i].sod_iscancel != 1) {
              exarray.push(tableRow[i])
            }
        }
        if(exarray.length == 1){
            alert("Cann't cancel 1 item bill, Please Cancel the Order in salesapproval")
            exarray = []
            return
        }
        exarray = []
        if(sodid == "") {
          console.log("index", index)
          setTotal(total-price)
          var input = [...tableRow]
          input.splice(index, 1)
          console.log("input", input)
          setTableRow(input)
        }
        else{
          var data = {
              "sod_id":  sodid,
              "user_id": userId
          }
          console.log("data", data)
          fetch(global.url + "salesOrderEditProductDelete", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("response", responseJson)
                window.location.reload()
                
            })
            .catch((error)=>{
                console.log(error)
            })
        }
      
    }

      const getBilltype = () => {

        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
  
        var data = {
          "type":  userdata.user_type,
          "companyid": company
        }
        fetch(global.url + "viewBillSetting", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            //   console.log("list response", responseJson)
              setBilltypelist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const getInvoiceno = () => {
                
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        
        var data = {
            "companyid": company
          }
        fetch(global.url + "stockTransferVoucherno", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              setInvoice(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const handleOnChange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].code = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setCode(event.currentTarget.value);
        getSearch(event.currentTarget.value, type)
    }

    const handleOnChange2 = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].code2 = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setCode2(event.currentTarget.value);
        getSearch2(event.currentTarget.value, type)
    }

    const getSearch = (text, type) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        if(type == "code") {
            var data = {
                "type" : type,
                "product_code":  text,
                "companyid" : company,
                "branchid" : branch
            }
        }
        else {
            var data = {
                "type" : type,
                "refcode":  text,
                "companyid" : company,
                "branchid" : branch
            }
        }
        console.log("searchdata", data)
        
        fetch(global.url + "getSearchOnlyStockprd", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response", responseJson)
            setProductlist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getSearch2 = (text, type) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        if(type == "code") {
            var data = {
                "type" : type,
                "product_code":  text,
                "companyid" : company,
                "branchid" : branch
            }
        }
        else {
            var data = {
                "type" : type,
                "refcode":  text,
                "companyid" : company,
                "branchid" : branch
            }
        }
        console.log("searchdata", data)
        
        fetch(global.url + "getSearchOnlyStockprd", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response", responseJson)
            setProductlist2(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const listClickrefcode = (index, productid, productrefcode,productname,products,image, iscombo) => {
        console.log("image", image)
          var input = [...tableRow]
          input[index].key = index
          input[index].products_id = productid
          input[index].code = productrefcode
          input[index].sales_iscombo = iscombo
          input[index].productname = productname
          input[index].pei_image = image
          input[index].colorsize = products
          input[index].addnew = true
          setSelectedindex(index)
          setTableRow([...tableRow, input])
          setTableRow([...tableRow, {key:"", batchid_from: "", batchid_to: "", stocktransfered: "", product_batch_id:"", product_batch_id2:"", product_id: "", code: "", code2: "",  productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, pb_stock: "", sales_iscombo: "",sod_id: "",color: "", color2: "", size: "", size2: "",colorsize: [], colorsize2: [], addnew: true,  fetch_products: [{key: "", productid: "", color: "", color2: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
          setCode("")
      }
      const listClickrefcode2 = (index, productid, productrefcode,productname,products,image, iscombo) => {
        console.log("image", image)
          var input = [...tableRow]
          input[index].key = index
          input[index].products_id = productid
          input[index].code2 = productrefcode
          input[index].sales_iscombo = iscombo
          input[index].productname2 = productname
          input[index].pei_image = image
          input[index].colorsize2 = products
          input[index].addnew = true
          setSelectedindex(index)
          setCode2("")
      }

  const getProductbybatch1 = (id, index) => {
   
    var data = {
        "pb_id" : id,
    }
    console.log("data", data)
    fetch(global.url + "getProductBatchById", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{

        setDetails(id, index, responseJson.data)
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const getProductbybatch2 = (id, index) => {
   
    var data = {
        "pb_id" : id,
    }
    console.log("data", data)
    fetch(global.url + "getProductBatchById", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{

        setDetails2(id, index, responseJson.data)
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const qtyChange = (event, index) => {
    var textArray1 = [...tableRow]
    if(event.target.value > parseFloat(textArray1[index].pb_stock)){
        console.log("event.target.value", event.target.value)
        console.log("event.target.value", textArray1[index].pb_stock)
        seterrormsg("Can't enter qty greater than stock")
    }
    else{
        seterrormsg("")
        console.log("price", textArray1[index].price)
        var totalprice = textArray1[index].price * event.target.value
        textArray1[index].sod_qty = event.target.value
        textArray1[index].stocktransfered = event.target.value
        textArray1[index].calcprice = totalprice
        textArray1[index].sod_total_amount = totalprice
        setTableRow(textArray1)
    }
}

const update = () => {
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    var userdata = JSON.parse(localStorage.getItem("user"))
    console.log("sj123",tableRow )
    const exampleArray = []
            var exampleSubarray = false
            for(var i = 0 ; i< tableRow.length; i++) {
                if(tableRow[i].product_batch_id == "" || tableRow[i].sales_isedit == 1) {
                    // tableRow.splice(i, 1)
                }
                else{
                    console.log("fetchproducts1",tableRow[i].fetch_products)
                    if(tableRow[i].fetch_products != undefined){
                        for(var j = 0 ; j< tableRow[i].fetch_products.length; j++) {

                            if(tableRow[i].fetch_products[j].fetch_id == "" && tableRow[i].sales_iscombo == 1){
                                alert("Empty Product in Combo product, Cannot Save Order")
                                exampleSubarray = true
                            }
                            else{
                                
                            }
                        }
                    }
                    
                    exampleArray.push(tableRow[i])
                } 
            }
            var data = {
                "voucherno": invoice,
                "refno": refno,
                "remarks": remarks,
                "companyid": company,
                "userid": userdata.user_id,
                "branchid": branch,
                "transfer_date": date,
                "transfer_branchid": branch2,
                "transfer_products": exampleArray,
            }
            console.log("data stock", data)
            fetch(global.url + "stockTransfer", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("response stock", responseJson)
                if(responseJson.error == true) {
                    alert(responseJson.message)
                   
                }
                else{
                    alert(responseJson.message)
                    window.location.reload()
                }
            })
            .catch((error)=>{
                console.log(error)
            })
}

const addRow = () => {
    setAddnew(true)
    setTableRow([...tableRow, {key:"",  batchid_from: "", batchid_to: "", stocktransfered: "", pbexp_id: "", product_batch_id:"", product_batch_id2:"", code: "", code2: "", productname: "", productname2: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, pb_stock: "", sales_iscombo: "", sod_id: "",color: "", color2: "", size: "", size2: "", colorsize: [], colorsize2: [], addnew: true, fetch_products: [{key: "", productid: "", color: "", color2: "",  size: "", size2: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "",  stock: ""}]}])
}

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Stock Transfer</p>
            </div>
            <Row>
                <Col sm={12}>
                    <Row>
                        <Col sm={6} lg={1} style={{marginTop:30}}>
                            <p className="label">Bill Type</p>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Bill Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={billType}
                                        label="Age"
                                        onChange={billtypeChange}
                                        size="small"
                                        
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {billtypelist.map((row, index) => {
                                        return (
                                            <MenuItem value={row.billset_id}>{row.billset_formtype}</MenuItem>
                                        )})}
                                    </Select>
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={1} style={{marginTop:30}}>
                            <p className="label">Voucher No.</p>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField disabled value={invoice} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={1} style={{marginTop:30}}>
                            <p className="label">Ref No.</p>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={refno}  onChange={(e) => setRefno(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} lg={1} style={{marginTop:30}}>
                            <p className="label">Doc Date</p>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="To"
                                value={date}
                                onChange={(newValue) => {
                                    setDate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        </Col>
                        <Col sm={6} lg={1} style={{marginTop:25}}>
                            <p className="label">Transfer Branch</p>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">Select Branch</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={branch2}
                                    label="Age"
                                    onChange={branchselect}
                                    size="small"
                                    
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {branchlist.map((item, index) => (
                                    <MenuItem value={item.branch_id}>{item.branch_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col>
                    </Row>

                </Col>
            </Row> 
            
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <Table bordered responsive>
                        <thead>
                            <tr>
                                <th>SlNo</th>
                                <th>Code</th>
                                <th>color-size</th>
                                <th>Product name</th>
                                <th>Expiry Date</th>
                                <th>Qty</th>
                                <th>Code</th>
                                <th>color-size</th>
                                <th>Product name</th>
                                <th>Close</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableRow.map((item, index) => {
                                return (
                                    <tr  style={item.sod_iscancel == 1 ? {background: 'red'}: null}>
                                        <td >{index+1}</td>
                                        <td>
                                            {
                                                item.addnew == false ?
                                                <input autocomplete="off" disabled ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} />:
                                                <input autocomplete="off" ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} />
                                            }
                                            
                                            {hasResults && selectedIndex == index && (
                                                <div className="autocomplete">
                                                    <ul ref={resultsRef} className="people scrolling-div">
                                                        {productlist.map(item => {
                                                            return (
                                                                item.products.map(result => {
                                                                    return (
                                                                    <li key={item.url}>
                                                                        <a href="javascript:void(0)" onClick={() => listClickrefcode(
                                                                            index, 
                                                                            item.products_id,
                                                                            item.product_refcode,
                                                                            item.product_name,
                                                                            item.products,
                                                                            item.pei_image,
                                                                            0
                                                                            )}>
                                                                        {item.product_refcode} - { item.product_name }
                                                                        </a>
                                                                    </li>
                                                                )
                                                                }) 
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            )}
                                        </td>
                                        {  
                                        item.addnew == true ? 
                                        <td>
                                            <FormControl sx={{width: '100%' }}>
                                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Select</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={item.product_batch_id}
                                                    label="Age"
                                                    onChange={colorChange}
                                                    size="small"
                                                >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {item.colorsize.map((row, index) => {
                                                    return (
                                                    <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                                )})}
                                                </Select>
                                            </FormControl>
                                        </td>
                                        : 
                                        <td></td>
                                        }
                                        <td style={{fontSize: 10}}>
                                            <p> {item.productname} - {item.color} - {item.size} </p>     
                                        </td>
                                        <td>
                                                <FormControl sx={{width: '100%' }}>
                                                        <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Select</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-helper-label"
                                                            id="demo-simple-select-helper"
                                                            value={item.pbexp_id}
                                                            label="Age"
                                                            onChange={dateChange}
                                                            size="small"
                                                        >
                                                        <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {expirydatelist.map((row, index) => {
                                                            return (
                                                            <MenuItem value={row.pbexp_id}>{row.pbexp_date}</MenuItem>
                                                        )})}
                                                        </Select>
                                                </FormControl>
                                        </td>
                                        <td>
                                            {item.sales_iscombo == 0 ||  item.sales_iscombo == null   ?
                                                <div>  
                                                    {item.addnew == false ?
                                                    <input min="1" disabled  autocomplete="off" type="number" name="query" value={item.sod_qty} onChange={(event) => qtyChange(event, index)} />: 
                                                    <input min="1"  autocomplete="off" type="number" name="query" value={item.sod_qty} onChange={(event) => qtyChange(event, index)} />
                                                    }
                                                    {
                                                        <label style={{fontSize: 12, fontWeight: 'bold', color: 'red'}}>stock: {item.pb_stock}</label>
                                                    }
                                                </div>: 
                                                null
                                            }
                                        </td>
                                        <td>
                                            {
                                                item.addnew == false ?
                                                <input autocomplete="off" disabled ref={inputRef} type="text" name="query" value={item.code2} onChange={(event) => handleOnChange2(event, index, "refcode")} />:
                                                <input autocomplete="off" ref={inputRef} type="text" name="query" value={item.code2} onChange={(event) => handleOnChange2(event, index, "refcode")} />
                                            }
                                            
                                            {hasResults2 && selectedIndex == index && (
                                                <div className="autocomplete">
                                                    <ul ref={resultsRef} className="people scrolling-div">
                                                        {productlist2.map(item => {
                                                            return (
                                                                item.products.map(result => {
                                                                    return (
                                                                    <li key={item.url}>
                                                                        <a href="javascript:void(0)" onClick={() => listClickrefcode2(
                                                                            index, 
                                                                            item.products_id,
                                                                            item.product_refcode,
                                                                            item.product_name,
                                                                            item.products,
                                                                            item.pei_image,
                                                                            0
                                                                            )}>
                                                                        {item.product_refcode} - { item.product_name }
                                                                        </a>
                                                                    </li>
                                                                )
                                                                }) 
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            )}
                                        </td>
                                        {  
                                        item.addnew == true ? 
                                        <td>
                                            <FormControl sx={{width: '100%' }}>
                                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Select</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={item.product_batch_id2}
                                                    label="Age"
                                                    onChange={colorChange2}
                                                    size="small"
                                                >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {item.colorsize2.map((row, index) => {
                                                    return (
                                                    <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                                )})}
                                                </Select>
                                            </FormControl>
                                        </td>
                                        : 
                                        <td></td>
                                        }
                                        <td style={{fontSize: 10}}>
                                            <p> {item.productname2} - {item.color2} - {item.size2} </p>     
                                        </td>
                                        <td>
                                            {item.sod_iscancel == 1 ? null : item.sales_isedit == 1 ? null: <Button onClick={() => deleteRow(index, item.amount, item.sod_id)} style={{backgroundColor: '#fff', border: 'none', boxShadow: 'none'}} variant="contained">< AiFillMinusCircle color="#6d37b9" fontSize={30} style={{marginRight: 10}} /></Button>}
                                        </td>
                                    </tr>
                                )
                            })}
                            
                        </tbody>
                    </Table>
                    <Button onClick={() => addRow()} style={{float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20, marginTop: 20}} variant="contained">Add New</Button>
                </Col>
            </Row>                         

            <div style={{marginTop: 30}}>
                <Row style={{marginTop: 20}}>
                    <Col xs={12} md={6}>
                        <TextareaAutosize 
                            value={remarks}  
                            onChange={(e) => setRemarks(e.target.value)}
                            minRows={3}
                            placeholder="Remarks"
                            style={{background: 'transparent', width: '100%'}}
                        />
                    </Col>
                </Row>
                
                <Row style={{marginTop: 20}}>
                    <Col xs={12} >
                        <ul className="list-inline">
                            <li><Button onClick={update} variant="outlined"><IconName.BsDownload style={{marginRight: 10}}/>Save</Button></li>
                            <li><Button variant="outlined"><GI.GiBinoculars style={{marginRight: 10}}/>Find</Button></li>
                            <li><Button variant="outlined"><BI.BiPrinter style={{marginRight: 10}}/>Print</Button></li>
                            <li><Button variant="outlined"><RI.RiDeleteBin6Line style={{marginRight: 10}}/>Delete</Button></li>
                            <li><Button variant="outlined"><RI.RiCloseFill style={{marginRight: 10}}/>Close</Button></li>
                        </ul>
                    </Col>
                </Row>
            </div>
          </Paper> 
      </div>
    )
}
export default StockTransfer;