import React, {Component, useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Loader from '../loader/loader';


const columns = [
  { id: '1', label: 'Sl No.', minWidth: 170,span: false },
  { id: '2', label: 'Order Type', minWidth: 170,span: false },
  {
    id: 'Action',
    label: 'Action',
    minWidth: 50,
    span: true
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}


function OrderType(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = useState("")

  const [modal, setModal] = useState(false);
  const [stateList, setStatelist] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [ordertype, setOrdertype] = React.useState("");
  const [ordertypeId, setOrdertypeId] = React.useState("");
  const [countryList, setCountryList] = React.useState([]);
  const [country, setCountry] = React.useState("")

  useEffect(() => {
    getData()
  }, [])


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const countryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getData = () => {
      var company = JSON.parse(localStorage.getItem("company"))
      var data = {
        "companyid" : company,
      }
      setLoader(true)
      fetch(global.url + "viewOrderType", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("respo", responseJson)
          if(responseJson.error == false) {
            setStatelist(responseJson.data)
            setLoader(false)
          }
          else{
            setLoader(false)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const onAdd = () => {
    setModal(true)
    setOrdertype("")
    setOrdertypeId("")
  }

const onSave = () =>{
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      "companyid" : company,
      "ordertypeid" : ordertypeId,
      "ordertype" : ordertype,
    }
 
    fetch(global.url + "addOrderType", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
  })
  .then((response)=> response.json())
  .then((responseJson)=>{
      console.log("responsejson", responseJson);
      if(responseJson.error == false) {
        getData()
        setModal(false)
        alert(responseJson.message)
      }
      if(responseJson.error == true) {
        alert(responseJson.message)
      }
  })
  .catch((error)=>{
      console.log(error)
  })
}
// const deleteClick = (id) =>  {
//   var data = {
//     'state_id' : id,
//     "user_id": userId
//   }
//     fetch(global.url + "deleteState", {
//       method: "POST",
//       headers: {
//           'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(data),
//     })
//     .then((response)=> response.json())
//     .then((responseJson)=>{
//       console.log("response", responseJson)
//         alert("Deleted")
//         getData()
//     })
//     .catch((error)=>{
//         console.log(error)
//     })
//   }

  const editClick =(
    id,
    name,                 
  ) => {
    setModal(true)
    setOrdertype(name)
    setOrdertypeId(id)
  }

  return (
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Order Type</p>
               <Button variant="contained" onClick={onAdd}>Add</Button>
            </div>
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 20 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stateList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell >
                                  {index + 1}
                              </TableCell>
                              <TableCell >
                                  {row.ordertype_name}
                              </TableCell>
                              <TableCell >
                                  <a href="javascript:void(0)" onClick={() => editClick(
                                    row.ordertype_id,
                                    row.ordertype_name
                                  )}>
                                    <FaIcons.FaEdit fontSize={20} color="blue" />
                                  </a>
                              </TableCell>
                           
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={stateList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>

          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Order Type Add
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField autoComplete="off" onChange={(e) => setOrdertype(e.target.value)} value={ordertype} id="outlined-basic" label="Order Type" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                  </Row>
              </Modal.Body>
           </Modal>
        </div>
  );
}

export default OrderType;
