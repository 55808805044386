import './Invoice.css';
import React, {Component, useEffect, useState, useRef} from 'react';
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { parse } from 'date-fns';

function Invoice(){
    const { id } = useParams();
    const [date, setDate] = React.useState("")
    const [voucherno, setVoucherno] = React.useState("")
    const [details, setDetails] = React.useState([])
    const [salestotal, setTotal] = React.useState(0)
    const [discount, setDiscount] = React.useState(0)
    const [grandtotal, setGrandtotal] = React.useState(0)
    const [cash, setCash] = React.useState(0)
    const [card, setCard] = React.useState(0)
    const [cashbill, setCashbill] = React.useState(0)
    const [paid, setPaid] = React.useState(0)
    const [balance, setBalance] = React.useState(0)
    const [mobile, setMobile] = React.useState("")


    useEffect(() => {
        grantotalClick()
    }, [salestotal, discount])

    const grantotalClick = () => {
        var total = parseFloat(salestotal) - parseFloat(discount)
        setGrandtotal(total)
    }

    useEffect(() => {
        paidClick()
    }, [cash, card])

    const paidClick = () => {
        var total = parseFloat(cash) + parseFloat(card)
        setPaid(total)
    }
    useEffect(() => {
        balanceClick()
    }, [grandtotal, paid])

    const balanceClick = () => {
        var total = parseFloat(paid) - parseFloat(grandtotal)
        setBalance(total)
    }

    useEffect(() => {
        var data = {
            "sales_id" : id,
        }
        
        fetch(global.url + "salesPrint", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("print details", responseJson)
            setDetails(responseJson.data.details)
            setTotal(responseJson.data.sales_total)
            setVoucherno(responseJson.data.sales_invoice)
            setDate(responseJson.data.sales_date)
            setDiscount(responseJson.data.sales_discount)
            setCash(responseJson.data.sales_cashreceived)
            setCard(responseJson.data.sales_card)
            setMobile(responseJson.data.customer_mobile)
            setCashbill(responseJson.data.sales_cash)
        })
        .catch((error)=>{
            console.log(error)
        })
    }, []);

    const print = () => {
        window.print()
      }
    return(
        <div>
            <Container fluid>
            <div>
                <Button onClick={print} style={{marginRight: 40, float: 'right', marginTop: 20}} variant="primary">Print</Button>
            </div>
                <Row >
                    <Col xs={12} style={{marginTop: 20}}> 
                    <div className="section-to-print">
                        <div className="invoice-box">
                            <div className="invoice-inside">
                                <p style={{textAlign: 'center', fontSize: 18, fontWeight: 'bold', marginBottom: 'unset'}}>Dekanet Beirut</p>
                                <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>Al Dafna - Doha</p>
                                <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>Tel :9444647200</p>
                               
                                <p style={{textAlign: 'center', fontSize: 22, fontWeight: 'bold'}}>{cash == 0 ? <span>Card Bill</span> : <span>Cash Bill</span>}</p>
                                <Row>
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>المورد</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Bill No: {voucherno}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>تاريخ</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Date: {date.split("-").reverse().join("-")}</p>
                                </Col>
                                {/* <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>أمين الصندوق</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Cashier: {voucherno}</p>
                                </Col> */}
                                {/* <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>تاريخ</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Time: {date}</p>
                                </Col> */}
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>طريقة / مدة الدفع</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Customer: Cash Customer</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>مرجع آخر</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Mobile: {mobile}</p>
                                </Col>
                                </Row>    
                                <div className='invoice-line' style={{width: '100%', height: 2, backgroundColor: '#d2d2d2'}}></div>
                                <div>
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                            <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>عدد</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>SLNo.</p>
                                            </th>
                                            {/* <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>منتج</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>Product</p></th> */}
                                            <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>كمية</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>Qty</p></th>
                                            <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>السعر</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>Price</p></th>
                                            <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>مجموع</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>Total</p></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                details.map((item, index) => {
                                                    return(
                                                        <>
                                                            <tr>
                                                                <td style={{borderBottom: 'none'}}>{index+1}</td>
                                                                {/* <td>{item.productname}</td> */}
                                                                <td>{(parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))} <br/>({item.sd_baseqty}x{item.unit})</td>
                                                                <td>{(parseFloat(item.price)/(parseFloat(item.sd_qty)/parseFloat(item.sd_baseqty))).toFixed(2)}</td>
                                                                <td>{parseFloat(item.sd_total_amount).toFixed(2)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={4} style={{fontSize: 13}}>
                                                                    {item.productname}
                                                                </td>
                                                            </tr>
                                                        </>
                                                        
                                                    )
                                               
                                                })
                                            }
                                            
                                            <tr>
                                        
                                                <td colSpan={3}>
                                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>Sub Total / المجموع الفرعي</p>
                                                    {discount == 0 ? null : 
                                                    <p style={{fontSize: 14, marginTop: 10, marginBottom: 'unset'}}>Discount / تخفيض</p>}
                                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset', marginTop: 10}}>Grand Total / المبلغ الإجمالي</p>
                                                </td>
                                                
                                                <td>
                                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>{parseFloat(salestotal).toFixed(2)}</p>
                                                    {discount == 0 ? null :
                                                    <p style={{fontSize: 14, marginTop: 10, marginBottom: 'unset'}}>{discount}</p>}
                                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset', marginTop: 10}}>{grandtotal.toFixed(2)}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>
                                                    {cash == 0 ? null :
                                                    <p style={{fontSize: 14, marginBottom: 'unset', marginTop: 10}}>Cash / نقدي</p>}
                                                    {card == 0 ? null :
                                                    <p style={{fontSize: 14, marginBottom: 'unset', marginTop: 10}}>Card / بطاقة</p>}
                                                </td>
                                                <td>
                                                    {cash == 0 ? null :
                                                    <p style={{fontSize: 14, marginTop: 10, marginBottom: 'unset'}}>{cash}</p>}
                                                    {card == 0 ? null :
                                                    <p style={{fontSize: 14, marginTop: 10, marginBottom: 'unset'}}>{card}</p>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>
                                         
                                                    <p style={{fontSize: 14, marginBottom: 'unset', marginTop: 10}}>Paid / مدفوع</p>
                                                 
                                                    <p style={{fontSize: 14, marginBottom: 'unset', marginTop: 10}}>Balance / الرصيد</p>
                                                </td>
                                                <td>
                                                   
                                                    <p style={{fontSize: 14, marginTop: 10, marginBottom: 'unset'}}>{paid.toFixed(2)}</p>
                                                   
                                                    <p style={{fontSize: 14, marginTop: 10, marginBottom: 'unset'}}>{balance.toFixed(2)}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div style={{marginTop: 30}}>
                                    <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>Thank You</p>
                                    <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>Visit Again!</p>
                                    {/* <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>Dekanet Beinut</p> */}
                                    
                                    {/* <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>procurement@alawadi.qa</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Invoice;